import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const DocPreview = ({ url, title }) => (
  <div>
    <Link to={url}>{title}</Link>
  </div>
)

DocPreview.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default DocPreview