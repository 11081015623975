import React from 'react'
import { graphql } from 'gatsby'
import styles from './support-index.module.scss'

// Components
import Layout from '../components/Layout'
import Section from '../components/Layout/Section'
import PageTitle from '../components/Elements/PageTitle'
import DocPreview from '../components/Documentation/DocPreview'

const SupportIndex = ({ data }) => {
  const { allFile: { edges: articles } } = data
  return (
    <Layout className={styles.blogIndex}>
      <PageTitle title="Support">
        Product documentation and frequently asked questions.
      </PageTitle>
      <Section>
        <ul className={styles.articleList}>
          {articles.map(({ node }) =>
            <li className={styles.articleItem} key={node.id}>
              <DocPreview url={node.childMarkdownRemark.fields.slug} title={node.childMarkdownRemark.frontmatter.title} />
            </li>
          )}
        </ul>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query SupportPageByDirectory {
    allFile(filter: { relativeDirectory: { eq: "support" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  }
`

export default SupportIndex